import React, { Component } from "react";
import CabPage from "../CabPage";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/esm/Button";
// import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FloatingLabel from "react-bootstrap/esm/FloatingLabel";
import axios from "axios";
import { CurDate, Separador } from "../k_functions.js";

class RelatFinanc extends Component {
  state = {
    validated: false,
    dIni: CurDate("YMD"),   // data de hoje
    dFim: CurDate("YMD"),   // data de hoje
    aClientes: [],          // contém a lista de clientes filtrados
    aClientesAll: [],       // contém todos os clientes
    iCodCli: 0,             // código do cliente selecionado
    aFormasAll: [],         // contém todos as formas de pagamento
    cTotCxF: "N",           // total por cliente e forma
    cTotCli: "N",           // total por cliente 
    cTotFor: "N",           // total por forma
    cTotDia: "N",           // total por dia
  };

  /* requisição com axios para ler lista de clientes */
  async componentDidMount() {
    // le clientes
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}TrataGetPost.php`,
      params: {
        prog_trata: "./Clientes/ClientesListar.php",
        func_trata: "ClientesListar"
      }
    })
    .then((response) => {
      let aClientes = [];
      aClientes = response.data;
      // inclui um cliente  fake, para que, se selecionado, signifique que nenhum cliente deve ser usado como filtro
      const aClientesAll = [{"id_cliente" : "0", "fantasia" : "TODOS"}, ...aClientes];
//      console.log(aClientesAll);
      this.setState({ aClientes: aClientesAll });
      this.setState({ aClientesAll: aClientesAll });
    })
    .catch(function (error) {console.log(error); alert(error)})

    // le formas de pagamento
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}TrataGetPost.php`,
      params: {
        prog_trata: "./FormasPag/FormasPagListar.php",
        func_trata: "FormasPagListar"
      }
    })
    .then((response) => {
//      console.log(response.data);
      let aFormas = [];
      aFormas = response.data;
      // inclui uma forma fake, para que, se selecionado, signifique que nenhuma forma deve ser usado como filtro
      const aFormasAll = [{"id_forma" : "0", "desc_forma" : "TODAS"}, ...aFormas];
      this.setState({ aFormasAll: aFormasAll });
    })
    .catch(function (error) {console.log(error); alert(error)})
  }

  handleSubmit = (event) => {
    // primeiro valida o formulario
    const form = event.currentTarget;
    let isValid = ! (form.checkValidity() === false);
    if (! isValid)
    {
      event.preventDefault();
      event.stopPropagation();
      isValid = false;
    } 

    // se os dados são válidos 
    if (isValid)
      this.setState({validated: true});
  };

  handleChange_dIni = () => e => {  
      let dIni = e.target.value;
      this.setState({ dIni });
  }; 

  handleChange_dFim = () => e => {  
    let dFim = e.target.value;
    this.setState({ dFim });
  };

  handleChangegTotCxF = () => e => {  
    let cTotCxF = (e.target.checked ? "S" : "N");
    this.setState({ cTotCxF });
  };

  handleChangegTotCli = () => e => {  
    let cTotCli = (e.target.checked ? "S" : "N");
    this.setState({ cTotCli });
  };

  handleChangegTotFor = () => e => {  
      let cTotFor = (e.target.checked ? "S" : "N");
      this.setState({ cTotFor });
  };

  handleChangegTotDia = () => e => {  
    let cTotDia = (e.target.checked ? "S" : "N");
    this.setState({ cTotDia });
  };      

  handleChangegSitu = () => e => {  
    //    console.log(e);
        let cSitu = e.target.id;
        this.setState({ cSitu });
  };      
    
  // filtra clientes conforme usuário vai digitando no campo filtrar
  handleLocalizar = (e) => {
    const aClientes = this.state.aClientesAll.filter(el => 
      ((el.fantasia.toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) ||
       (el.id_cliente.toString().indexOf(e.target.value) > -1)));
    this.setState({aClientes: aClientes});
  }

  render() {
    return (
      <>
        <CabPage titulo="Relatório Financeiro" paginavoltar="/home" />
        <div className="App-body">
          <form validated={this.validated} onSubmit={this.handleSubmit} action="/finrelres">
            <Row>
              <Col>
                <FloatingLabel controlId="floatingDatIni" label="Vencimento Inicial">
                <Form.Control required type="date" name="DatIni" defaultValue={this.state.dIni} onChange={this.handleChange_dIni} />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="floatingDatFim" label="Vencimento Final">
                  <Form.Control required type="date" name="DatFim" defaultValue={this.state.dFim} onChange={this.handleChange_dFim} />
                </FloatingLabel>
              </Col>
            </Row>
            <br/>
            <input className="form-control mr-sm-2" type="search" placeholder="Filtrar cliente" aria-label="Search" onChange={this.handleLocalizar}/>
            <FloatingLabel controlId="floatingCliente" label="Cliente">
              <Form.Select name="CodCli" htmlSize="3" className="sSizeBoxCliente">
                {this.state.aClientes.map((oCliente) => (
                  <option key={oCliente.id_cliente} value={oCliente.id_cliente}>{oCliente.id_cliente + ' - ' + oCliente.fantasia}</option>
                ))}
              </Form.Select>
            </FloatingLabel>
            <br/>
            <FloatingLabel controlId="floatingForma" label="Forma">
              <Form.Select name="CodFor">
                {this.state.aFormasAll.map((oForma) => (
                  <option key={oForma.id_forma} value={oForma.id_forma}>{oForma.id_forma + ' - ' + oForma.desc_forma}</option>
                ))}
              </Form.Select>
            </FloatingLabel>
            <br/>
            <Separador />
            Sumarizar por:
            <br/>
            <Row><Col>
            <Form.Check inline type="switch" label="Cliente" name="cTotCli"
              value={this.state.cTotCli}
              onChange={this.handleChangegTotCli()}
            />
            </Col><Col>
            <Form.Check inline type="switch" label="Forma" name="cTotFor"
              value={this.state.cTotFor}
              onChange={this.handleChangegTotFor()}
            />
            </Col></Row>
            <Row><Col>
            <Form.Check inline type="switch" label="Cliente e forma" name="cTotCxF"
              value={this.state.cTotCxF}
              onChange={this.handleChangegTotCxF()}
            />
            </Col><Col>
            <Form.Check inline type="switch" label="Vencimento" name="cTotDia"
              value={this.state.cTotDia}
              onChange={this.handleChangegTotDia()}
            />
            </Col></Row>
            <Separador />
            Exibir:&nbsp;&nbsp;
            <Form.Check inline type="radio" label="Emitidos"  name="cSitu" id="Emi" value={this.state.cSitu} onChange={this.handleChangegSitu()} checked />
            <Form.Check inline type="radio" label="Pagos"     name="cSitu" id="Pag" value={this.state.cSitu} onChange={this.handleChangegSitu()} />
            <Form.Check inline type="radio" label="Pendentes" name="cSitu" id="Pen" value={this.state.cSitu} onChange={this.handleChangegSitu()} />
            <Separador />            
            <br/>
            <center>
              <Button as="button" type="submit" id="bt_add" 
                variant="success" size="lg">Gerar Relatório
              </Button>
            </center>
          </form>
        </div>
      </>
    )
  }
}

export default RelatFinanc;