import React, { Component } from "react";
import CabPage from "../CabPage";
import Table from 'react-bootstrap/Table';
import axios from "axios";
import { FormataValor } from "../k_functions.js";
import { FaBinoculars } from "react-icons/fa";
import Alert from 'react-bootstrap/Alert';
import Nav from "react-bootstrap/esm/Nav";
import Button from "react-bootstrap/esm/Button";

class RelatFinanc extends Component {
  state = {
    aParcelas: [],    // contém a lista de parcelas filtradas
    aParcelasAll: []  // contém todos as parcelas
  };

  /* requisição com axios para ler parcelas */
  async componentDidMount() {
//    console.log(this.props);
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}TrataGetPost.php`,
      params: {
        prog_trata: "./Financas/FinancasListar.php",
        func_trata: "FinancasListar",
        sts_par: this.props.sSts,
        dat_ini: this.props.dIni,
        dat_fim: this.props.dFim,
        dtiniVd: this.props.dIVd,
        dtfimVd: this.props.dFVd,
        cod_cli: this.props.cCli,
        cod_for: 0, 
      }
    })
    .then((response) => {
      this.setState({ aParcelas: response.data });
      this.setState({ aParcelasAll: response.data });
    })
    .catch(function (error) {console.log(error); alert(error)})
  }

  // filtra clientes conforme usuário vai digitando no campo filtrar
  handleLocalizar = (e) => {
    const aParcelas = this.state.aParcelasAll.filter(el => 
      ((el.fantasia.toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)) ||
       (el.id_venda.toString().indexOf(e.target.value) > -1));
      this.setState({aParcelas: aParcelas});
  }
  
  render() {
    return (
      <>
        {this.props.sSts === "Quitado" && 
          <CabPage titulo="Últimas Quitadas" subtitulo="Quitadas nos últimos 5 minutos" paginavoltar="/finsel/qui" classSubTit="sFonte6Branco"/>
        }
        {this.props.sSts !== "Quitado" && 
          <CabPage titulo="Listar Parcelas" subtitulo="Até 200 parcelas são listadas" paginavoltar="/finsel/lis" classSubTit="sFonte6Branco"/>
        }
        <form>
          <input className="form-control mr-sm-2" type="search" placeholder="Localizar venda ou cliente" aria-label="Search" onChange={this.handleLocalizar} />
        </form>
        {this.state.aParcelas.msg === 'ERRO' &&
          <Alert variant="warning">Não há parcelas para listar !</Alert>
        }
        {this.state.aParcelas.length > 0 && 
          <>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th width="15%">#Parc<br/><br/>#Venda</th>
                  <th width="25%">Ve: Dt Venda <br/>Vc: Dt Vcto<br/>Pg: Dt Pgto</th>
                  <th width="40%">Cliente<br/><br/>Forma</th>
                  <th width="20%">Parcela<br/>$ Orig<br/>$ Quit</th>
                </tr>
              </thead>
              <tbody>
              {this.state.aParcelas.length > 0 && 
                this.state.aParcelas.map((oParcela, index) => (
                <tr key={index}>
                <td align="center">{oParcela.id_vendas_parc}
                  <Nav.Link href={`/pardet/${oParcela.id_vendas_parc}/ret/lis`}>
                      <FaBinoculars />
                  </Nav.Link>
                  {oParcela.id_venda}</td>              
                <td>Ve: {oParcela.dt_venda}<br/>Vc: {oParcela.dt_vcto}<br/>Pg: {oParcela.dt_pgto}</td>
                <td>{oParcela.fantasia}<br/><br/>{oParcela.desc_forma}</td>
                <td align='right'>{oParcela.num_parcela}<br/>{FormataValor(oParcela.valor_parcela)}<br/>{FormataValor(oParcela.valor_quitado)}</td>
                </tr>
              ))}
              </tbody>
            </Table>
            <i>Registros listados: {this.state.aParcelas.length}</i><br/><br/>
            {this.props.sSts === "Quitado" &&
              <center>
                <Nav.Link href={`/finsel/qui`}>
                  <Button as="button" id="bt_add" variant="success" size="sm">Nova quitação</Button>
                </Nav.Link>
              </center>
            }
          </>
        }
      </>
    );
  }
}

export default RelatFinanc;