import React, { Component } from "react";
import CabPage from "../CabPage";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/esm/Button";
// import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FloatingLabel from "react-bootstrap/esm/FloatingLabel";
import axios from "axios";
import { CurDate } from "../k_functions.js";

class QuitarFiltrar extends Component {
  state = {
    validated: false,
    dIni: CurDate("YMD"),   // data de inicio de vencimento
    dFim: CurDate("YMD"),   // data de fim de vencimento
    dIniVd: CurDate("YMD"),   // data de inicio de vencimento
    dFimVd: CurDate("YMD"),   // data de fim de vencimento
    aClientes: [],          // contém a lista de clientes filtrados
    aClientesAll: [],       // contém todos os clientes
    iCodCli: 0,             // código do cliente selecionado
  };

  /* requisição com axios para ler lista de clientes */
  async componentDidMount() {
    // le clientes
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}TrataGetPost.php`,
      params: {
        prog_trata: "./Clientes/ClientesListar.php",
        func_trata: "ClientesListar"
      }
    })
    .then((response) => {
      let aClientes = [];
      aClientes = response.data;
      // inclui um cliente  fake, para que, se selecionado, signifique que nenhum cliente deve ser usado como filtro
      const aClientesAll = [{"id_cliente" : "0", "fantasia" : "TODOS"}, ...aClientes];
//      console.log(aClientesAll);
      this.setState({ aClientes: aClientesAll });
      this.setState({ aClientesAll: aClientesAll });
    })
    .catch(function (error) {console.log(error); alert(error)})
  }

  handleSubmit = (event) => {
    // primeiro valida o formulario
    const form = event.currentTarget;
    let isValid = ! (form.checkValidity() === false);
    if (! isValid)
    {
      event.preventDefault();
      event.stopPropagation();
      isValid = false;
    } 

    // se os dados são válidos 
    if (isValid)
      this.setState({validated: true});
  };

  handleChange_dIni = () => e => {  
      let dIni = e.target.value;
      this.setState({ dIni });
  }; 

  handleChange_dFim = () => e => {  
    let dFim = e.target.value;
    this.setState({ dFim });
  };

  handleChange_dIniVd = () => e => {  
    let dIniVd = e.target.value;
    this.setState({ dIniVd });
  }; 

  handleChange_dFimVd = () => e => {  
    let dFimVd = e.target.value;
    this.setState({ dFimVd });
  };

  // filtra clientes conforme usuário vai digitando no campo filtrar
  handleLocalizar = (e) => {
    const aClientes = this.state.aClientesAll.filter(el => 
      ((el.fantasia.toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) ||
       (el.id_cliente.toString().indexOf(e.target.value) > -1)));
    this.setState({aClientes: aClientes});
  }

  render() {
    return (
      <>
        <CabPage titulo="Filtrar Parcelas" paginavoltar="/home" 
          subtitulo={this.props.target==="qui" ? "(Quitar)" : "(Listar)"} classSubTit="sFonte6Branco"/>        
        <div className="App-body">
          <form validated={this.validated} onSubmit={this.handleSubmit} action={this.props.target==="qui" ? "/quitarlis" : "/finlis"}>
            <Row>
              <Col>
                <FloatingLabel controlId="floatingDatIni" label="Vencimento Inicial">
                <Form.Control type="date" name="DatIni" onChange={this.handleChange_dIni} />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="floatingDatFim" label="Vencimento Final">
                  <Form.Control type="date" name="DatFim" onChange={this.handleChange_dFim} />
                </FloatingLabel>
              </Col>
            </Row>
            <br/>
            <Row>
              <Col>
                <FloatingLabel controlId="floatingDatIniVd" label="Data Venda Inicial">
                <Form.Control type="date" name="DatIniVd" onChange={this.handleChange_dIniVd} />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="floatingDatFimVd" label="Data Venda Final">
                  <Form.Control type="date" name="DatFimVd" onChange={this.handleChange_dFimVd} />
                </FloatingLabel>
              </Col>
            </Row>
            <br/>
            <input className="form-control mr-sm-2" type="search" placeholder="Filtrar cliente" aria-label="Search" onChange={this.handleLocalizar}/>
            <FloatingLabel controlId="floatingCliente" label="Cliente">
              <Form.Select name="CodCli" htmlSize="3" className="sSizeBoxCliente">
                {this.state.aClientes.map((oCliente) => (
                  <option key={oCliente.id_cliente} value={oCliente.id_cliente}>{oCliente.id_cliente + ' - ' + oCliente.fantasia}</option>
                ))}
              </Form.Select>
            </FloatingLabel>
            <br/>
            <center>
              <Button as="button" type="submit" id="bt_add" 
                variant="success" size="lg">Filtrar Parcelas
              </Button>
            </center>
          </form>
        </div>
      </>
    )
  }
}

export default QuitarFiltrar;