import React, { Component } from "react";
import CabPage from "../CabPage";
import Table from 'react-bootstrap/Table';
import axios from "axios";
import { FaBinoculars, FaRegListAlt } from "react-icons/fa";
import Button from "react-bootstrap/esm/Button";
import Nav from "react-bootstrap/esm/Nav";
import { FormataValor, FormataData, CodConsum } from "../k_functions.js";
import Alert from 'react-bootstrap/Alert';

class ListarVendasResult extends Component {
  state = {
    aVendas: [],  // contém a lista de vendas filtrados
    aVendasAll: []  // contém todos as vendas
  };

  /* requisição com axios para ler lista de vendas */
  async componentDidMount() {
//    console.log(this.props);
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}TrataGetPost.php`,
      params: {
        prog_trata: "./Vendas/VendasListar.php",
        func_trata: "VendasListar",
        dat_ini: this.props.dIni,
        dat_fim: this.props.dFim,
        tip_dat: this.props.tDat,
        cod_cli: this.props.cCli,
        ven_ini: this.props.vIni,
        ven_fim: this.props.vFim,
        lis_ven: this.props.nLis,
      }
    })
    .then((response) => {
      // console.log(response.data);
      this.setState({ aVendas: response.data });
      this.setState({ aVendasAll: response.data });
    })
    .catch(function (error) {console.log(error); alert(error)})
  }

  render() {
    return (
      <>
        {this.props.nLis=='350' && <CabPage titulo="Listar Vendas" subtitulo="Até 350 vendas são listadas" paginavoltar="/venlis" classSubTit="sFonte6Branco"/>}
        {this.props.nLis!=350 && <CabPage titulo="Últimas 10 Vendas" paginavoltar="/home" />}
        {this.state.aVendas.msg === 'ERRO' &&
          <Alert variant="warning">Não há vendas para listar !</Alert>
        }
        {this.state.aVendas.length > 0 &&
          <>
          <Table striped bordered hover>
            <thead>
              <tr align='center'>
                <th width="20%">#Venda<br/>Usuário</th>
                <th width="50%">Cliente<br/>Total</th>
                <th width="15%" >Dt Venda<br/>Dt Lanç</th>
                <th width="15%" colSpan={2}>
                  <center>
                    <Nav.Link href={`/vender`}>
                      <Button as="button" id="bt_add" variant="success" size="sm">+</Button>
                    </Nav.Link>
                  </center>
                </th>
              </tr>
            </thead>
            <tbody>
            {this.state.aVendas.map((oVenda, index) => (
              <tr key={index}>
                <td>{oVenda.id_venda}<br/>{oVenda.login}</td>
                <td>{oVenda.fantasia + (oVenda.id_cliente === CodConsum() ? ": " + oVenda.nome_consum : "")}<br/>{FormataValor(parseFloat(oVenda.valor_total))}</td>
                <td style={{backgroundColor: oVenda.data_venda != oVenda.data_lan_venda ? "yellow" : ""}}>
                  {FormataData(oVenda.data_venda)}<br/>{FormataData(oVenda.data_lan_venda)}
                </td>
                <td><Nav activeKey="/vendet">
                    <Nav.Link href={`/vendet/${oVenda.id_venda}`}>
                      <FaBinoculars />
                    </Nav.Link>
                  </Nav></td><td>
                  <Nav activeKey="/vencup">
                    <Nav.Link href={`/vencup/${oVenda.id_venda}`}>
                      <FaRegListAlt />
                    </Nav.Link>
                  </Nav>
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
          <i>Registros listados: {this.state.aVendas.length}</i>
          </>
        }
      </>
    );
  }
}

export default ListarVendasResult;