import React, { Component } from "react";
import CabPage from "../CabPage";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/esm/Button";
// import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FloatingLabel from "react-bootstrap/esm/FloatingLabel";
import axios from "axios";
import { CurDate } from "../k_functions.js";

class ListarVendas extends Component {
  state = {
    validated: false,
    dIni: CurDate("YMD"),   // data de hoje
    dFim: CurDate("YMD"),   // data de hoje
    tDat: "V",              // tipo de data (venda, lançamento ou ambas)
    aClientes: [],          // contém a lista de clientes filtrados
    aClientesAll: [],       // contém todos os clientes
    iCodCli: 0,             // código do cliente selecionado
    vIni: 0,                // venda inicial
    vFim: 0,                // venda final
  };

  /* requisição com axios para ler lista de clientes */
  async componentDidMount() {
    // le clientes
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}TrataGetPost.php`,
      params: {
        prog_trata: "./Clientes/ClientesListar.php",
        func_trata: "ClientesListar"
      }
    })
    .then((response) => {
      let aClientes = [];
      aClientes = response.data;
      // inclui um cliente  fake, para que, se selecionado, signifique que nenhum cliente deve ser usado como filtro
      const aClientesAll = [{"id_cliente" : "0", "fantasia" : "TODOS"}, ...aClientes];
//      console.log(aClientesAll);
      this.setState({ aClientes: aClientesAll });
      this.setState({ aClientesAll: aClientesAll });
    })
  }

  handleSubmit = (event) => {
    // primeiro valida o formulario
    const form = event.currentTarget;
    let isValid = ! (form.checkValidity() === false);
    if (! isValid)
    {
      event.preventDefault();
      event.stopPropagation();
      isValid = false;
    } 

    // se os dados são válidos 
    if (isValid)
      this.setState({validated: true});
  };

  handleChange_dIni = () => e => {  
      let dIni = e.target.value;
      this.setState({ dIni });
  }; 

  handleChange_dFim = () => e => {  
    let dFim = e.target.value;
    this.setState({ dFim });
  };

  handleChange_tDat = () => e => {  
    let tDat = e.target.value;
    this.setState({ tDat });
  };

  handleChange_vIni = () => e => {  
    let vIni = e.target.value;
    this.setState({ vIni });
  }; 

  handleChange_vFim = () => e => {  
    let vFim = e.target.value;
    this.setState({ vFim });
  };

  // filtra clientes conforme usuário vai digitando no campo filtrar
  handleLocalizar = (e) => {
    const aClientes = this.state.aClientesAll.filter(el => 
      ((el.fantasia.toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) ||
       (el.id_cliente.toString().indexOf(e.target.value) > -1)));
    this.setState({aClientes: aClientes});
  }

  render() {
    return (
      <>
        <CabPage titulo="Listar Vendas" paginavoltar="/home" />
        <div className="App-body">
          <form validated={this.validated} onSubmit={this.handleSubmit} action="/venlisres">
            <Row>
              <Col>
                <FloatingLabel controlId="floatingDatIni" label="Data Inicial">
                <Form.Control required type="date" name="DatIni" defaultValue={this.state.dIni} onChange={this.handleChange_dIni} />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="floatingDatFim" label="Data Final">
                  <Form.Control required type="date" name="DatFim" defaultValue={this.state.dFim} onChange={this.handleChange_dFim} />
                </FloatingLabel>
              </Col>
            </Row>
            <br/>
            <FloatingLabel controlId="floatingTipoData" label="Tipo de Data">
              <Form.Select name="TipDat" defaultValue={this.state.tDat} onChange={this.handleChange_tDat}>
                  <option key="0" value="V">Data da Venda</option>
                  <option key="1" value="L">Data do Lançamento</option>
                  <option key="2" value="A">Data da Venda ou do Lançamento</option>
              </Form.Select>
            </FloatingLabel>
            <br/>
            <Row>
              <Col>
                <FloatingLabel controlId="floatingVenIni" label="Venda Inicial">
                <Form.Control required type="number" defaultValue="0" name="VenIni" min="0" max="999999" onChange={this.handleChange_vIni} />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="floatingVenFim" label="Venda Final">
                  <Form.Control required type="number" defaultValue="999999" name="VenFim" min="0" max="999999" onChange={this.handleChange_vFim} />
                </FloatingLabel>
              </Col>
            </Row>
            <br/>
            <input className="form-control mr-sm-2" type="search" placeholder="Filtrar cliente" aria-label="Search" onChange={this.handleLocalizar}/>
            <FloatingLabel controlId="floatingCliente" label="Cliente">
              <Form.Select name="CodCli" htmlSize="3" className="sSizeBoxCliente">
                {this.state.aClientes.map((oCliente) => (
                  <option key={oCliente.id_cliente} value={oCliente.id_cliente}>{oCliente.id_cliente + ' - ' + oCliente.fantasia}</option>
                ))}
              </Form.Select>
            </FloatingLabel>
            <br/><br/>
            <center>
              <Button as="button" type="submit" id="bt_add" 
                variant="success" size="lg">Listar
              </Button>
            </center>
          </form>
        </div>
      </>
    )
  }
}

export default ListarVendas;