import React, { Component } from "react";
import CabPage from "../CabPage.js";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import FloatingLabel from "react-bootstrap/esm/FloatingLabel";
import Button from "react-bootstrap/esm/Button";
import { Separador, FormataValor, LeProdUnidPrec, trataAxios } from "../k_functions.js";
import axios from "axios";

class VenderAlterar extends Component {
  state = {
    validated: false,
    /*
    iSelProd: -1, // produto atualmente selecionado
    iSelUnid: -1, // unidade atualmente selecionada
    aProds: [], aProdsIdx: [], 
    aUnids: [], aUnidsIdx: [], 
    */
    nNewQtde: 0,
    nNewVUni: 0,
    nNewQBon: 0,
    aDetVenNew: [{id_venda: 0, id_venda_lin: 0, login: "", fantasia: "", nome_razao: "", desc_produto: "",
      desc_unidade: "", qtd_vendida: 0, valor_unit: 0}], 
    aDetVenOld: [{id_venda: 0, id_venda_lin: 0, login: "", fantasia: "", nome_razao: "", desc_produto: "",
      desc_unidade: "", qtd_vendida: 0, valor_unit: 0}], 
  }

  handleChange_QBon = e => {  
    let qBon = e.target.value;
    this.setState({ nNewQBon: qBon });
  };

  // seta o campo valor total, que é a qtd x valor unitário
  calcTotPro = e => {
    let vTotal = document.querySelector("#Qtd").value * document.querySelector("#VUni").value;
    document.querySelector("#VTot").value = FormataValor(Math.round(vTotal * 100) / 100, "input");
    // habilita o botão de adicionar e de limpar, mesmo q o $ unit seja 0, pois pode vender a preço zero
    document.querySelector("#bt_alt").disabled = (document.querySelector("#Qtd").value === "");
//    console.log(this.state.aDetVenNew);
    if (e.target.id === "Qtd")
    {
      const nNewQtd = e.target.value;
      this.setState({nNewQtde: nNewQtd});
    }
    else
    {
      const nNewVUn = e.target.value;
      this.setState({nNewVUni: nNewVUn});
    }
  }

  async componentDidMount() {
    let i = 0;
    let oPUP = LeProdUnidPrec();
    this.setState({aProds: oPUP.aProds, aUnids: oPUP.aUnids});
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}TrataGetPost.php`,
      params: {
        prog_trata: "./Vendas/VendasDetalhe.php",
        func_trata: "VendasDetalhe",
        id_venda_lin: this.props.idVendaLin,
        capa_e_linha: "N"
      }
    })
    .then((response) => {
      // https://stackoverflow.com/questions/55547534/updating-one-state-variable-changing-the-data-in-another-state-variable
      this.setState({ aDetVenOld: JSON.parse(JSON.stringify(response.data["venda"]))});
      this.setState({ aDetVenNew: JSON.parse(JSON.stringify(response.data["venda"]))});
      // seta o id do produto atual para que ele já apareça selecionado e tb abra 
      // automaticamente a seleção das unidades
      for (i=0; i<oPUP.aProds.length; i++)
      {
        if (oPUP.aProds[i].cod === response.data["venda"][0].id_produto)
          this.setState({iSelProd: i});
      }
      // seta o id da unidade atual para que ela já apareça selecionada
      for (i=0; i<oPUP.aUnids.length; i++)
      {
        if (oPUP.aUnids[i].cod === response.data["venda"][0].id_unidade)
          this.setState({iSelUnid: i});
      }
      // seta qtd e valor unitario e bonificacao
      this.setState({nNewQtde: this.state.aDetVenNew[0].qtd_vendida});
      this.setState({nNewVUni: this.state.aDetVenNew[0].valor_unit});
      this.setState({nNewQBon: this.state.aDetVenNew[0].qtd_bonif});
    })
    .catch(function (error) { console.log(error); alert(error) })
  }

  handleSubmit = (event) => {
    // primeiro valida o formulario
    const form = event.currentTarget;

    let isValid = ! (form.checkValidity() === false);

    if ((! isValid) || (! window.confirm("Confirma a alteração da venda ?")))
    {
      event.preventDefault();
      event.stopPropagation();
      isValid = false;
    } 
    this.setState({validated: true});

    // se os dados são válidos e confirmou a venda
    if (isValid)
    {
      // bloqueia botão para não clicar de novo
      document.querySelector("#bt_alt").innerHTML = "Aguarde...";
      document.querySelector("#bt_alt").classList.remove("btn-success");
      document.querySelector("#bt_alt").classList.add("btn-warning");
      document.querySelector("#bt_alt").disabled = true;

      // chama o programa php para salvar os dados
      const dadosVen = {
        nusu: localStorage.getItem("user"), // this.props.nUsuario,
        nqtd: this.state.nNewQtde * 1,
        nvun: this.state.nNewVUni * 1,
        nqbo: this.state.nNewQBon * 1,
        idvl: this.props.idVendaLin,
        prog_trata: "./Vendas/VendasAlterar.php",
        func_trata: "AlteraVenda"
      }
//      console.log(dadosVen);
      trataAxios(dadosVen);
    }
  };

  render() {
    return (
      <>
        <CabPage titulo="Alterar Item da Venda" paginavoltar="/venlis" />
        <div className="App-body">
          <>
            <Row>
              <Col xs="3">
                <FloatingLabel controlId="floatingVendas" label="Venda">
                  <Form.Control type="text" value={this.state.aDetVenOld[0].id_venda} disabled/>
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="floatingUsuario" label="Item">
                    <Form.Control type="text" value={this.state.aDetVenOld[0].id_venda_lin} disabled/>
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="floatingUsuario" label="Usuário">
                    <Form.Control type="text" value={this.state.aDetVenOld[0].login} disabled/>
                </FloatingLabel>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <FloatingLabel controlId="floatingCliente" label="Cliente">
                    <Form.Control type="text" value={this.state.aDetVenOld[0].fantasia + 
                        " (" + this.state.aDetVenOld[0].nome_razao + ")"} disabled/>
                </FloatingLabel>
              </Col>
            </Row>
            <br/>
            <div className="label">Dados atuais</div>
            <Separador />
            <div>{this.state.aDetVenOld[0].desc_produto} - {this.state.aDetVenOld[0].desc_unidade}</div>
            <Row>
              <Col>
                <FloatingLabel controlId="QtdOld" label="Qtd">
                  <Form.Control disabled type="number" value={this.state.aDetVenOld[0].qtd_vendida}
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="VUniOld" label="$ Unit.">
                  <Form.Control disabled type="number" value={this.state.aDetVenOld[0].valor_unit}
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="VTotOld" label="$ Total">
                  <Form.Control disabled type="number" value={this.state.aDetVenOld[0].qtd_vendida * this.state.aDetVenOld[0].valor_unit} />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="QBonOld" label="Bonif">
                  <Form.Control disabled type="number" value={this.state.aDetVenOld[0].qtd_bonif} />
                </FloatingLabel>
              </Col>
            </Row>
            <br/>
            <div className="label">Novos dados</div>
            <Separador />
            <form ovalidated={this.validated} onSubmit={this.handleSubmit} action={"/vendet/" + this.state.aDetVenOld[0].id_venda}>
              <Row>
                <Col>
                  <FloatingLabel controlId="Qtd" label="Qtd">
                    <Form.Control required type="number" min="0.01" step=".01" value={this.state.nNewQtde}
                      onFocus={(e) => {e.target.select()}} onChange={(e) => {this.calcTotPro(e)}} 
                    />
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel controlId="VUni" label="$ Unit.">
                    <Form.Control required type="number" min="0.00" step=".01" value={this.state.nNewVUni}
                      onFocus={(e) => {e.target.select()}} onChange={(e) => {this.calcTotPro(e)}} 
                    />
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel controlId="VTot" label="$ Total">
                    <Form.Control type="number" disabled value={this.state.nNewVUni * this.state.nNewQtde} />
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel controlId="QBon" label="Bonif">
                    <Form.Control required type="number" min="0.00" step=".01" value={this.state.nNewQBon}
                      onFocus={(e) => {e.target.select()}} onChange={this.handleChange_QBon} 
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <br/><center>
                <Button as="button" type="submit" id="bt_alt" 
                  variant="warning" size="lg">Alterar venda
                </Button>
              </center>
            </form>
          </>
        </div>
      </>
    );
  }
}

export default VenderAlterar;