import React, { Component } from "react";
import CabPage from "../CabPage";
import Table from 'react-bootstrap/Table';
import axios from "axios";
import { FaBinoculars } from "react-icons/fa";
import Nav from "react-bootstrap/esm/Nav";

class ProdutosListar extends Component {
  state = {
    aProdutos: [],    // contém os produtos filtrados
    aProdutosAll: []  // contém todos os produtos
  };

  /* requisição com axios para ler lista de produtos */
  async componentDidMount() {
    axios({
      method: 'get',
//      url: 'http://localhost:8080/React/ctc3/src/server/TrataGedtPost.php',
      url: `${process.env.REACT_APP_API}TrataGetPost.php`,
      params: {
        prog_trata: "./Produtos/ProdutosListar.php",
        func_trata: "ProdutosListar"
      }
    })
      .then((response) => {
        // console.log(response.data);
        this.setState({ aProdutos: response.data });
        this.setState({ aProdutosAll: response.data });
      })
      .catch(function (error) { console.log(error); alert(error) })
  }

  // filtra produtos conforme usuário vai digitando no campo filtrar
  handleLocalizar = (e) => {
    const aProdutos = this.state.aProdutosAll.filter(el => 
      ((el.desc_produto.toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) ||
       (el.id_produto.toString().indexOf(e.target.value) > -1)));
    this.setState({aProdutos: aProdutos});
  }

  render() {
    return (
      <>
        <CabPage titulo="Produtos" paginavoltar="/home" />
        <form>
          <input className="form-control mr-sm-2" type="search" placeholder="Localizar" aria-label="Search" onChange={this.handleLocalizar} />
        </form>
        <Table striped bordered hover size="sm">
          <thead>
            <tr align='center'>
              <th width="10%">ID</th>
              <th width="60%">Descrição</th>
              <th width="20%">Origem(s)</th>
              <th width="10%">Detalhe</th>
            </tr>
          </thead>
          <tbody>
            {this.state.aProdutos.map((oProduto, index) => (
              <tr key={index} align='center'>
                <td>{oProduto.id_produto}</td>
                <td>{oProduto.desc_produto}</td>
                <td>{oProduto.id_produto + '; ' + oProduto.produtos_orig}</td>
                <td>
                  <Nav activeKey="/prodet">
                    <Nav.Link href={`/prodet/${oProduto.id_produto}`}>
                      <FaBinoculars />
                    </Nav.Link>
                  </Nav>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    );
  }
}

export default ProdutosListar;