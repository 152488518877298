import React, { Component } from "react";
import Table from 'react-bootstrap/Table';
import VenderTot from "./VenderTot";
import Button from "react-bootstrap/esm/Button";
import Alert from 'react-bootstrap/Alert';
import { FormataValor } from "../k_functions.js";
import Form from 'react-bootstrap/Form';
import { Separador } from "../k_functions.js";

class VenderCar extends Component {
  render() {
    return (
      <>
        <div className="App-body">
          <VenderTot aCarrinho={this.props.aCarrinho}/>
          <Separador />
          <div className="carrinho">
            {this.props.aCarrinho.aItens.length === 0 && 
              <Alert variant="warning">Carrinho vazio !</Alert>
            }
            {this.props.aCarrinho.aItens.length > 0 && (
                <Table striped bordered hover size="sm">
                  <thead>
                      <tr key={1000} align='center'>
                          <th width="30%">Produto</th>
                          <th width="10%">Qt&nbsp;Ven<br/>Qt&nbsp;Bon</th>
                          <th width="20%">Und</th>
                          <th width="15%">$ Unit</th>
                          <th width="15%">$ Tot</th>
                          <th width="5%"></th>
                      </tr>
                  </thead>
                  <tbody>
                      {this.props.aCarrinho.aItens.map((oItem, index) => (
                      <tr key={oItem.item} align='center'>
                            <td align='left'><b>{this.props.aProds[oItem.prod].desc}</b><br/>
                              <Form.Group controlId={"ret_"+oItem.prod}>
                                <Form.Check type="checkbox" label="Retirar já" defaultChecked="checked" onClick={() => {this.props.rotRet(index)}}></Form.Check>
                              </Form.Group>
                            {oItem.reti}
                            </td>
                            <td>
                              {oItem.qtde}<br/>
                              {oItem.qbon}
                            </td>
                            <td>{this.props.aUnids[oItem.unid].desc}</td>
                            <td>{FormataValor(oItem.vuni)}</td>
                            <td>{FormataValor(oItem.vtot)}</td>
                            <td>
                            <Button as="button" id="bt_del" variant="danger" 
                              size="sm" onClick={() => {this.props.rotDel(oItem)}}>-</Button>
                            </td>
                          </tr>
                      ))}
                  </tbody>
                </Table>
              )}
          </div>
        </div>
      </>
    );
  }
}

export default VenderCar;