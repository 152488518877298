import React, { Component } from "react";
import CabPage from "../CabPage";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/esm/Button";
// import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FloatingLabel from "react-bootstrap/esm/FloatingLabel";
import axios from "axios";
import { CurDate, Separador } from "../k_functions.js";

class RelatVendas extends Component {
  state = {
    validated: false,
    aClientes: [],          // contém a lista de clientes filtrados
    aClientesAll: [],       // contém todos os clientes
    iCodCli: 0,             // código do cliente selecionado
    aProdutosAll: [],       // contém todos os produtos
    cTotPMA: "N",           // total de pendências em meses anteriores
    cSitu:   "E",           // emitidos, pagos, pendentes
    /*
    dIni: CurDate("YMD"),   // data de hoje
    dFim: CurDate("YMD"),   // data de hoje
    cTotCli: "N",           // total por cliente
    cTotPro: "N",           // total por produto
    cTotCliPro: "N",        // total por cliente e produto
    cTotDia: "N",           // total por dia
    cTotDiaPro: "N",        // total por dia e produto
    cTotA_M: "N",           // total por ano/mês
    */
    dIni: localStorage.getItem('dIni'),
    dFim: localStorage.getItem('dFim'),
    cTotCli: localStorage.getItem('cTotCli'),
    cTotPro: localStorage.getItem('cTotPro'),
    cTotCliPro: localStorage.getItem('cTotCliPro'),
    cTotDia: localStorage.getItem('cTotDia'),
    cTotDiaPro: localStorage.getItem('cTotDiaPro'),
    cTotA_M: localStorage.getItem('cTotA_M'),
  };

  /* requisição com axios para ler lista de clientes */
  async componentDidMount() {
    // le clientes
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}TrataGetPost.php`,
      params: {
        prog_trata: "./Clientes/ClientesListar.php",
        func_trata: "ClientesListar"
      }
    })
    .then((response) => {
      let aClientes = [];
      aClientes = response.data;
      // inclui um cliente  fake, para que, se selecionado, signifique que nenhum cliente deve ser usado como filtro
      const aClientesAll = [{"id_cliente" : "0", "fantasia" : "TODOS"}, ...aClientes];
//      console.log(aClientesAll);
      this.setState({ aClientes: aClientesAll });
      this.setState({ aClientesAll: aClientesAll });
    })
    .catch(function (error) {console.log(error); alert(error)})
    // le produtos
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}TrataGetPost.php`,
      params: {
        prog_trata: "./Produtos/ProdutosListar.php",
        func_trata: "ProdutosListar"
      }
    })
    .then((response) => {
//      console.log(response.data);
      let aProdutos = [];
      aProdutos = response.data;
      // inclui um produto fake, para que, se selecionado, signifique que nenhum produto deve ser usado como filtro
      const aProdutosAll = [{"id_produto" : "0", "desc_produto" : "TODOS"}, ...aProdutos];
//      console.log(aProdutosAll);
      this.setState({ aProdutosAll: aProdutosAll });
    })
    .catch(function (error) {console.log(error); alert(error)})
  }

  handleSubmit = (event) => {
    // primeiro valida o formulario
    const form = event.currentTarget;
    let isValid = ! (form.checkValidity() === false);
    if (! isValid)
    {
      event.preventDefault();
      event.stopPropagation();
      isValid = false;
    } 

    // se os dados são válidos 
    if (isValid)
    {
      // salva dados de input para uso em novas execuções do relatório
      localStorage.setItem('dIni', document.querySelector("#floatingDatIni").value);
      localStorage.setItem('dFim', document.querySelector("#floatingDatFim").value);
      localStorage.setItem('cTotCli', document.getElementsByName("cTotCli")[0].value);
      localStorage.setItem('cTotPro', document.getElementsByName("cTotPro")[0].value);
      localStorage.setItem('cTotCliPro', document.getElementsByName("cTotCliPro")[0].value);
      localStorage.setItem('cTotDia', document.getElementsByName("cTotDia")[0].value);
      localStorage.setItem('cTotDiaPro', document.getElementsByName("cTotDiaPro")[0].value);
      localStorage.setItem('cTotA_M', document.getElementsByName("cTotA_M")[0].value);
      this.setState({validated: true});
    }
  };

  handleChange_dIni = () => e => {  
      let dIni = e.target.value;
      this.setState({ dIni });
  }; 

  handleChange_dFim = () => e => {  
    let dFim = e.target.value;
    this.setState({ dFim });
  };

  handleChangegTotCli = () => e => {  
    let cTotCli = (e.target.checked ? "S" : "N");
    this.setState({ cTotCli });
  };      

  handleChangegTotPro = () => e => {  
    let cTotPro = (e.target.checked ? "S" : "N");
    this.setState({ cTotPro });
  };      

  handleChangegTotCliPro = () => e => {  
    let cTotCliPro = (e.target.checked ? "S" : "N");
    this.setState({ cTotCliPro });
  };

  handleChangegTotDia = () => e => {  
    let cTotDia = (e.target.checked ? "S" : "N");
    this.setState({ cTotDia });
  };      

  handleChangegTotDiaPro = () => e => {  
    let cTotDiaPro = (e.target.checked ? "S" : "N");
    this.setState({ cTotDiaPro });
  };

  handleChangegTotA_M = () => e => {  
    let cTotA_M = (e.target.checked ? "S" : "N");
    this.setState({ cTotA_M });
  };      

  // pendencias de meses anteriores
  handleChangegTotPMA = () => e => {  
    let cTotPMA = (e.target.checked ? "S" : "N");
    this.setState({ cTotPMA });
  };      

  // filtra clientes conforme usuário vai digitando no campo filtrar
  handleLocalizar = (e) => {
    const aClientes = this.state.aClientesAll.filter(el => 
      ((el.fantasia.toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) ||
       (el.id_cliente.toString().indexOf(e.target.value) > -1)));
    this.setState({aClientes: aClientes});
  }

  render() {
    return (
      <>
        <CabPage titulo="Relatório de Vendas" paginavoltar="/home" />
        <div className="App-body">
          <form validated={this.validated} onSubmit={this.handleSubmit} action="/venrelres">
            <Row>
              <Col>
                <FloatingLabel controlId="floatingDatIni" label="Data Inicial">
                <Form.Control required type="date" name="DatIni" defaultValue={this.state.dIni} onChange={this.handleChange_dIni} />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="floatingDatFim" label="Data Final">
                  <Form.Control required type="date" name="DatFim" defaultValue={this.state.dFim} onChange={this.handleChange_dFim} />
                </FloatingLabel>
              </Col>
            </Row>
            <i className="sFonte7Verde">Data da venda, e não a data em que a venda foi lançada.</i>
            <br/><br/>
            <FloatingLabel controlId="floatingProduto" label="Produto">
              <Form.Select name="CodPro">
                {this.state.aProdutosAll.map((oProduto) => (
                  <option key={oProduto.id_produto} value={oProduto.id_produto}>{oProduto.id_produto + ' - ' + oProduto.desc_produto}</option>
                ))}
              </Form.Select>
            </FloatingLabel>
            <br/>
            <input className="form-control mr-sm-2" type="search" placeholder="Filtrar cliente" aria-label="Search" onChange={this.handleLocalizar}/>
            <FloatingLabel controlId="floatingCliente" label="Cliente">
              <Form.Select name="CodCli" htmlSize="3" className="sSizeBoxCliente">
                {this.state.aClientes.map((oCliente) => (
                  <option key={oCliente.id_cliente} value={oCliente.id_cliente}>{oCliente.id_cliente + ' - ' + oCliente.fantasia}</option>
                ))}
              </Form.Select>
            </FloatingLabel>
            <br/>
            <Separador />
            Sumarizar por:
            <br/>
            <Row><Col>
              <Form.Check inline type="switch" label="Cliente" name="cTotCli"
                value={this.state.cTotCli} checked={this.state.cTotCli === "S"}
                onChange={this.handleChangegTotCli()}
              />
            </Col><Col>
              <Form.Check inline type="switch" label="Produto" name="cTotPro"
                value={this.state.cTotPro} checked={this.state.cTotPro === "S"}
                onChange={this.handleChangegTotPro()}
              />
            </Col></Row>
            <Row><Col>
              <Form.Check inline type="switch" label="Cliente e produto" name="cTotCliPro"
                value={this.state.cTotCliPro} checked={this.state.cTotCliPro === "S"}
                onChange={this.handleChangegTotCliPro()}
              />
            </Col><Col>
              <Form.Check inline type="switch" label="Dia" name="cTotDia"
                value={this.state.cTotDia} checked={this.state.cTotDia === "S"}
                onChange={this.handleChangegTotDia()}
              />
            </Col></Row>
            <Row><Col>
              <Form.Check inline type="switch" label="Dia/Produto" name="cTotDiaPro"
                value={this.state.cTotDiaPro} checked={this.state.cTotDiaPro === "S"}
                onChange={this.handleChangegTotDiaPro()}
              />
            </Col><Col>
              <Form.Check inline type="switch" label="Ano/mês" name="cTotA_M"
                value={this.state.cTotA_M} checked={this.state.cTotA_M === "S"}
                onChange={this.handleChangegTotA_M()}
              />
            </Col></Row>
            {/*
            <Separador />
            <Form.Check inline type="switch" label="Exibir pendências de meses anteriores" name="cTotPMA"
              value={this.state.cTotPMA}
              onChange={this.handleChangegTotPMA()}
            />
            */}
            <br/><br/>
            <center>
              <Button as="button" type="submit" id="bt_add" 
                variant="success" size="lg">Gerar Relatório
              </Button>
            </center>
          </form>
        </div>
      </>
    )
  }
}

export default RelatVendas;