import React, { Component } from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import FloatingLabel from "react-bootstrap/esm/FloatingLabel";
import Button from "react-bootstrap/esm/Button";
import { Separador, FormataValor, SetaVUni, ShowUnids } from "../k_functions.js";

/* da forma abaixo nao funcionou; então deixei a chamada em cada botão 
// após carregar a página, adiciona um eventlistener onClick aos botões de produto e unidades
// para que, quando clicados, chamem a função setaVUni
window.onload=function(){
  const arrBotoes = document.querySelectorAll('[name^="bt_"]');
  for (let i = 0; i < arrBotoes.length; i++)
    arrBotoes[i].addEventListener("click", function() { setaVUni(this.id) });
}
*/

function restoreButton () {
  document.querySelector("#bt_add").innerHTML = "Adicionar ao carrinho";
  document.querySelector("#bt_add").classList.add("btn-success");
  document.querySelector("#bt_add").classList.remove("btn-warning");
}

// "desseleciona" produto, unidade, zera $ unit e total e deixa estes campos disabled
const resetForm = (e) => {
  document.querySelector("input[type='radio'][name='bt_prod']:checked").checked = false;
  document.querySelector("input[type='radio'][name='bt_unid']:checked").checked = false;
  document.querySelector("#VUni").value = 0;
  document.querySelector("#Qtd").value = 0;
  document.querySelector("#VTot").value = 0;
  document.querySelector("#VUni").disabled = true;
  document.querySelector("#Qtd").disabled = true;

  document.querySelector("#QBo").value = 0;
  document.querySelector("#QBo").disabled = true;

  document.querySelector("#qEstDis").value = 0;
  document.querySelector("#qEstRet").value = 0;
  document.querySelector("#qEstTot").value = 0;

//  document.querySelector("#bt_add").disabled = true;
  setTimeout(restoreButton, 2000);
}

// seta o campo valor total, que é a qtd x valor unitário
const calculaTotal = (e) => {
  let vTotal = document.querySelector("#Qtd").value * document.querySelector("#VUni").value;
  document.querySelector("#VTot").value = FormataValor(Math.round(vTotal * 100) / 100, "input");
  // habilita o botão de adicionar e de limpar, mesmo q o $ unit seja 0, pois pode vender a preço zero
  document.querySelector("#bt_add").disabled = (document.querySelector("#Qtd").value === "");
}

class VenderPro extends Component {
  state = {
    iSelProd: -1  // produto atualmente selecionado
  }

  // chamado ao clicar no botão de [Adicionar ao Carrinho]
  // cria um objeto oItem, depois passa este objeto para a rotina
  // addItem (chamada na linha this.props.rotAdd), a qual inclui o objeto dentro do array aItens que é
  // um dos campos do objeto aCarrinho
  handleAdicionar = e => {
    e.preventDefault();
    let oItem = {
      item: null, 
      // prod contém a posição no array aProds ref ao produto selecionado
      prod: document.querySelector("input[type='radio'][name='bt_prod']:checked").value,
      // codp é o codigo no banco de dados do produto selecionado e será atribuído a seguir
      codp: null,
      // unid contém a posição no array aUnids ref à unidade selecionada
      unid: document.querySelector("input[type='radio'][name='bt_unid']:checked").value,
      // codu é o codigo no banco de dados da unidade selecionada e será atribuído a seguir
      codu: null,
      vuni: document.querySelector("#VUni").value * 1, 
      qtde: document.querySelector("#Qtd").value * 1,
      qbon: document.querySelector("#QBo").value * 1,
      vtot: 0,
      reti: true // indica que o material terá retirada imediada
    };
    // atribuição do código do item no banco de dados
    oItem.codp = this.props.aProds[oItem.prod].cod;
    oItem.codu = this.props.aUnids[oItem.unid].cod;
    oItem.vtot = Math.round(oItem.vuni * oItem.qtde * 100)/100;
    this.props.rotAdd(oItem);
    document.querySelector("#bt_add").innerHTML = "Produto adicionado ao carrinho !";
    document.querySelector("#bt_add").classList.remove("btn-success");
    document.querySelector("#bt_add").classList.add("btn-warning");
    resetForm(e);
  };
 
  render() {
    return (
      <>
        <div className="App-body">
          <>
            <Separador />
            <form onSubmit={this.handleAdicionar}>
              <Row><Col>
              <ul className="btn-prod">
                {this.props.aProds.map((oProd, index) => (
                  <li key={index}><input required type="radio" id={"p" + index} value={index} 
                    name="bt_prod" onClick={(e) => {this.setState({iSelProd: index}); 
                    SetaVUni(index, this.props.aProds, this.props.iSelProd, "")}}/>
                  <label htmlFor={"p"+index}>{oProd.desc}</label></li>
                ))}
              </ul>
              </Col></Row>
              <Separador />
              <Row><Col>
              {  
                <ShowUnids aProds={this.props.aProds} aUnids={this.props.aUnids} 
                  iSelProd={this.state.iSelProd} iSelUnid={-1} cSufixo={""} /> 
              }
              </Col></Row>
              <Separador />
              <Row>
                <Col xs="4">
                  <FloatingLabel controlId="Qtd" label="Qtd">
                    <Form.Control required type="number" disabled min="0.01" step=".01"
                      onFocus={(e) => {e.target.select()}} 
                      onChange={(e) => {calculaTotal(e)}} 
                    />
                  </FloatingLabel>
                </Col>
                <Col xs="4">
                  <FloatingLabel controlId="VUni" label="$ Unit.">
                    <Form.Control type="number" disabled min="0.00" step=".01"
                      onFocus={(e) => {e.target.select()}} 
                      onChange={(e) => {calculaTotal(e)}} 
                    />
                  </FloatingLabel>
                </Col>
                <Col xs="4">
                  <FloatingLabel controlId="VTot" label="$ Total">
                    <Form.Control type="number" disabled />
                  </FloatingLabel>
                </Col>
              </Row>
              <br/>
              <Row>
                <Col xs="4">
                  <FloatingLabel controlId="QBo" label="Bonif">
                    <Form.Control required type="number" disabled min="0.00" step=".01"
                      onFocus={(e) => {e.target.select()}} 
                    />
                  </FloatingLabel>
                </Col>
                <Col xs="8">
                <center>
                  <Button as="button" type="submit" id="bt_add" 
                    variant="success" size="lg">Adicionar ao carrinho
                  </Button>
                </center>
              </Col>
              </Row>
            </form>
            <Separador />
            Estoque
            <Row>
              <Col>
                <FloatingLabel controlId="qEstDis" label="Disponível">
                    <Form.Control type="number" disabled value={this.state.qEstDis} />
                </FloatingLabel>
              </Col><Col>
                <FloatingLabel controlId="qEstRet" label="A Retirar">
                    <Form.Control type="number" disabled value={this.state.qEstRet} />
                </FloatingLabel>
              </Col><Col>
                <FloatingLabel controlId="qEstTot" label="Total">
                    <Form.Control type="number" disabled value={this.state.qEstTot} />
                </FloatingLabel>
              </Col>
            </Row>
          </>
        </div>
      </>
    );
  }
}

export default VenderPro;